@import 'reset';
@import 'theme';

* {
  box-sizing: border-box;
}

body {
  background-color: var(--color-body-bg);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
